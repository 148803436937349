<template>
  <b-overlay
    :show="data === null || disabledCache"
    rounded="sm"
  >
    <b-form
      v-if="data"
    >
      <b-row>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-group
            :label="$t('admin.labels.maintenance_mode')"
            label-for="maintenance_mode"
            :state="errors && errors.maintenance_mode ? false : null"
          >
            <v-select
              id="maintenance_mode"
              v-model="data.maintenance_mode"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="maintenance_mode"
            />
            <b-form-invalid-feedback v-if="errors && errors.maintenance_mode">
              {{ errors.maintenance_mode[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Clear system cache"
            label-for="cache"
          >
            <b-button
              variant="danger"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="disabledCache"
              block
              @click="clearCache"
            >
              Clear
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('admin.labels.language')"
            label-for="language"
          >
            <v-select
              v-model="data.language"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="languages"
              :reduce="val => val.value"
              :clearable="false"
              input-id="language"
            />
            <b-form-invalid-feedback v-if="errors && errors.language">
              {{ errors.language[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('admin.labels.admin_language')"
            label-for="admin_language"
          >
            <v-select
              v-model="data.admin_language"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="languages"
              :reduce="val => val.value"
              :clearable="false"
              input-id="admin_language"
            />
            <b-form-invalid-feedback v-if="errors && errors.admin_language">
              {{ errors.admin_language[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="hidden"
        >
          <b-form-group
            :label="$t('admin.labels.currency')"
            label-for="currency"
          >
            <v-select
              v-model="data.currency"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="currencies"
              :reduce="val => val.value"
              :clearable="false"
              input-id="currency"
              disabled
            />
            <b-form-invalid-feedback v-if="errors && errors.currency">
              {{ errors.currency[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="hidden"
        >
          <b-form-group
            :label="$t('admin.labels.admin_currency')"
            label-for="admin_currency"
          >
            <v-select
              v-model="data.admin_currency"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="currencies"
              :reduce="val => val.value"
              :clearable="false"
              input-id="admin_currency"
              disabled
            />
            <b-form-invalid-feedback v-if="errors && errors.admin_currency">
              {{ errors.admin_currency[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>

      <b-row
        v-if="$ability.can('update', 'settings')"
        class="mt-2"
      >
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit"
          >
            {{ $t('admin.buttons.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import StatusMixin from '@/mixins/StatusMixin'
import GlobalMixin from '@/mixins/GlobalMixin'

export default {
  mixins: [GlobalMixin, StatusMixin],
  data() {
    return {
      currencies: [],
      languages: [],
      data: null,
      language: null,
      disabledCache: false,
    }
  },
  async beforeCreate() {
    await this.$http('/api/languages')
      .then(response => {
        this.languages = this.$options.filters.transformForVSelect(response.data.data, 'code', 'title')
      })
    await this.$http('/api/currencies')
      .then(response => {
        this.currencies = this.$options.filters.transformForVSelect(response.data.data, 'code', 'title')
      })

    await this.$http('/api/admin/settings', { params: { group: 'general' } }).then(response => {
      this.data = this.transformData(response.data)

      /* eslint-disable camelcase */
      this.language = response.data.admin_language
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async submit() {
      await this.$http.put('/api/admin/settings/general', this.data)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')
          await localStorage.setItem('localization', JSON.stringify({
            admin_language: this.data.admin_language,
            language: this.data.language.value,
          }))
          await this.changeLanguage()
          if (this.language !== this.data.admin_language) {
            window.location.reload()
          }

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    clearCache() {
      this.confirm(() => {
        this.disabledCache = true
        setTimeout(() => {
          this.disabledCache = false
        }, 60000)
        this.$http.post('/api/admin/cache')
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('notifications.successfully'),
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        title: 'Запустити скидання?',
        text: 'Скидання триває 5-10 хв у фоновому режимі (декілька разів нажимати не треба) і оновлює скрипти та переклади. У цей час не проводити дії з адмінкою через ризик втрати вашої роботи. Рекомендовано скидати кеш не частіше 1 разу на добу. Бажано скидати у період найменшої активності користувачів.',
      })
    },
    changeLanguage() {
      this.$i18n.locale = this.data.admin_language
      this.$i18n.fallbackLocale = this.data.language
    },
    transformData(item) {
      return {
        maintenance_mode: item.maintenance_mode,
        language: item.language,
        admin_language: item.admin_language,
        currency: item.currency,
        admin_currency: item.admin_currency,
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
